<template>
  <div class="service-provider user-profile mx-auto" style="z-index: 400">
    <div class="flex overflow-hidden bg-white sp-profile-wrap">
      <sidebar :rating="rating" :name="name" :image="image" />
      <div class="bg-gray-900 opacity-50 hidden fixed inset-0 z-10" id="sidebarBackdrop"></div>
      <div id="main-content" class="h-full w-full bg-gray-50 relative overflow-y-auto lg:ml-64">
        <main>
          <router-view />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import consultantSideBar from '@/views/consultant-pages/sidebar.vue';

export default {
  name: 'consultant_private',
  components: {
    sidebar: consultantSideBar,
  },
  data() {
    return {
      name: null,
      image: null,
      rating: 5,
    };
  },
  mounted() {
    let user = this.$route.params.user;
    this.name = user.name;
    this.image = user.image;
    this.rating = 5;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
